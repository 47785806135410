<template>
  <div class="choosed_user_cell--container">
    <img class="choosed_user_cell--avatar"
      v-if="data.avatar.url"
      :src="data.avatar.url" />
    <img v-else class="choosed_user_cell--avatar"
      src="../../../assets/icon_default_avatar.svg" />
    <div class="choosed_user_cell--userinfo">
      <span class="choosed_user_cell--name">{{ data.fullname }}</span>
      <div class="choosed_user_cell--role">
        <img src="../../../assets/icon_user_profile.svg" width="20" />
        {{ data.role_label }}
      </div>
    </div>
    <img class="choosed_user_cell--remove" width="24"
      src="../../../assets/icon_trash.svg"
      v-on:click="onDeleteUser(data.id)" />
  </div>
</template>
<script>

export default {
  props: {
    data: Object
  },
  methods: {
    onDeleteUser(id) {
      this.$emit('onDelete', id)
    }
  }
}

</script>
<style scoped>
.choosed_user_cell--container {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #dddddd;
  padding-top: 15px;
  padding-bottom: 15px;
}

.choosed_user_cell--avatar {
  height: 40px;
  width: 40px;
  margin-right: 20px;
  border-radius: 50px;
}

.choosed_user_cell--userinfo {
  display: flex;
  flex-direction: column;
  flex: 1
}

.choosed_user_cell--role {
  display: flex;
  flex-direction: row;
}

.choosed_user_cell--role img {
  margin-right: 10px;
}

.choosed_user_cell--remove {
  cursor: pointer;
}
</style>
