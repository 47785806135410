<template>
  <div class="choose_user--container">
		<a-input-search 
      v-model="search"
      placeholder="Cari pengguna"
      @change="onSearch"/>

    <div class="choose-user--list">
      <div v-for="user of filterUsers" v-bind:key="user.id"
        v-on:click="onClickUser(user.id)">
        <ChooseUserCell :data="user" />
      </div>
    </div>
	</div>
</template>
<script>

import ChooseUserCell from "./ChooseUserCell.vue"
import { getUsers } from '../../../api/forum.api'
import ForumModel from "../ForumModel"

export default {
  components: {
    ChooseUserCell
  },
  props: {
    defaultUsers: []
  },
  data: () => {
    return {
      search: "",
      filterUsers: [],
      users: []
    }
  },
  created() {
		this.loadUser();
	},
  methods: {
    onSearch() {
      this.filterUsers = []
      ForumModel.methods.bindUser(this.filterUsers, this.users)
      this.filterUsers = this.filterUsers.filter(item => {
        return item.fullname.toLowerCase().includes(this.search.toLowerCase());
      })
    },

    loadUser() {
			getUsers({ filter: 'active' }).then(result => {
				const { results } = result.data;
        ForumModel.methods.bindData(this.users, this.filterUsers, results)
        this.setDefaultSelected()
			})
		},

    onClickUser(id) {
      ForumModel.methods.setSelected(this.users, this.filterUsers, id)
      const choosedUsers = ForumModel.methods.getSelected(this.users)
      this.$emit('onchoosed', choosedUsers)
    },

    setDefaultSelected() {
      for(const user of this.defaultUsers) {
        this.onClickUser(user)
      }
    }
  }
}

</script>
<style scoped>
.choose_user--container {
  padding-top: 20px;
}

.choose-user--list {
  margin-top: 10px;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
