<template>
	<a-modal
		title="Daftar Pengguna"
		:visible="visible"
		:confirm-loading="loading"
		@ok="handleAssignUser"
		@cancel="cancelAssignUser">
		<a-menu v-model="current" mode="horizontal">
      <a-menu-item key="choose_user" @click="onClickChooseUser"> <a-icon type="user" />Pilih Pengguna</a-menu-item>
			<a-menu-item key="choosed_user" @click="onClickChoosedUser"> <a-icon type="user" />Pengguna Terpilih </a-menu-item>
		</a-menu>
		<ChooseUser v-show="page == 0"
			:defaultUsers="defaultUsers"
			@onchoosed="onChoosedUser"
			ref="chooseUser" />
		<ChoosedUser v-show="page == 1" 
			:users="filterChoosedUser"
			@onDelete="onDeleteUser"
			@onSearch="onSearchUser"/>
	</a-modal>
</template>
<script>

import ChooseUser from './ChooseUser.vue';
import ChoosedUser from './ChoosedUser.vue';
import ForumModel from '../ForumModel';

export default {
	components: {
		ChooseUser,
		ChoosedUser
	},
	props: {
		visible: Boolean,
		defaultUsers: Array
	},
	data: () => {
		return {
			filterChoosedUser: [],
			loading: false,
			current: ['choose_user'],
			page: 0
		}
	},
	methods: {
		handleAssignUser() {
			this.$emit('onSubmit', this.choosedUsers)
		},

		cancelAssignUser() {
			this.$emit('onCancel')
		},

		onChoosedUser(users) {
			this.choosedUsers = []
			this.filterChoosedUser = []
			ForumModel.methods.bindUser(this.choosedUsers, users)
			ForumModel.methods.bindUser(this.filterChoosedUser, users)
		},

		onDeleteUser(id) {
			this.$refs.chooseUser.onClickUser(id)
		},

		onSearchUser(search) {
			this.filterChoosedUser = []
			ForumModel.methods.bindUser(this.filterChoosedUser, this.choosedUsers)
			this.filterChoosedUser = this.filterChoosedUser.filter(item => {
				return item.fullname.toLowerCase().includes(search.toLowerCase())
			})
		},

		onClickChooseUser() {
			this.page = 0
			this.current = ['choose_user'];
		},

		onClickChoosedUser() {
			this.page = 1
			this.current = ['choosed_user'];
		}
	}
}

</script>
