<template>
  <div class="choosed_user--container">
		<a-input-search 
      v-model="search"
      placeholder="Cari pengguna" 
      enter-button 
      @change="onSearch"/>

    <div class="choosed-user--list">
      <div v-for="user of users" v-bind:key="user.id">
        <ChoosedUserCell :data="user" @onDelete="onDeleteUser" />
      </div>
    </div>
	</div>
</template>
<script>

import ChoosedUserCell from "./ChoosedUserCell.vue"

export default {
  components: {
    ChoosedUserCell
  },
  props: {
    users: Array
  },
  data: () => {
    return {
      search: ""
    }
  },
  methods: {
    onSearch() {
      this.$emit('onSearch', this.search)
    },

    onDeleteUser(id) {
      this.$emit('onDelete', id)
    }
  }
}

</script>
<style scoped>
.choosed_user--container {
  padding-top: 20px;
}

.choosed-user--list {
  margin-top: 10px;
  min-height: 400px;
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
